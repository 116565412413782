
export function zip<T, U>(arr1: T[], arr2: U[]): [T, U][] {
    if (arr1.length !== arr2.length) {
        throw new Error("Arrays have different lengths!");
    }

    const result: [T, U][] = [];

    for (let i = 0; i < arr1.length; i++) {
        result.push([arr1[i]!, arr2[i]!]);
    }

    return result;
}



export function nullCheck<T>(arg: T | null | undefined, errMsg = "value is null like"): T{
    if (arg === undefined || arg === null) {
        throw new TypeError(`${errMsg}, Value of null-checked is: ${arg}`);
    } else {
        return arg
    }
}


export function beSure(wyr: boolean, errMsg: string) {
    if (!wyr) {
        console.error(errMsg)
        if (typeof window !== 'undefined' && typeof document !== 'undefined') {
            // in Browser environment
            alert(errMsg)
        }
        throw new Error(errMsg)
    }
}

export function beSureNoZeroLength<T extends string | Array<any>>(lengthy: T, errMsg: string) {
    if (lengthy.length === 0) {
        const errorMessage = `"${lengthy}" has length of 0` + " " + errMsg
        throw new Error(errorMessage)
    } else {
        return lengthy
    }
}

export const cyrb53 = function (str: string, seed = 0): number { // zostawic nazwe funkcji
    let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}

// could use string as seed
export const randNumGenMulberry32 = (seed: number): () => number => {
    beSure(typeof seed === "number", `Seed: ${seed} isn't a number`)
    return function () {
        let t = seed += 0x6D2B79F5;
        t = Math.imul(t ^ (t >>> 15), t | 1);
        t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
        return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
    }
}

export const sleep = (ms: number) => new Promise(r => setTimeout(r, ms))

export const beSureItIsNumber = (value: unknown, errMsg: string): number => {
    beSure(typeof value === "number", `${getProtoype(value)} isn't a number ${errMsg}`)
    beSure(!Number.isNaN(value), "Should be number but is NaN " + errMsg)
    return value as number
}

export const beSureItIsString = (value: unknown, errMsg: string): string => {
    beSure(typeof value === "string", `${getProtoype(value)} isn't a string ${errMsg}`)
    return value as string
}

export function beSureItIsAnyObject<T>(value: T, errMsg: string): NonNullable<T> {
    beSure(typeof value === "object", `${getProtoype(value)} isn't an object at all ${errMsg}`)
    return value as NonNullable<T>
}

export function beSureItIsPlainObject<T>(value: T, errMsg: string): NonNullable<T> {
    beSure(Object.prototype.toString.call(value) === '[object Object]', `${getProtoype(value)} isn't a plain object  ${errMsg}`)
    return value as NonNullable<T>
}

export function beSureItIsArray<T>(value: T, errMsg: string): NonNullable<T> {
    beSure(Array.isArray(value), `${getProtoype(value)} isn't an array  ${errMsg}`)
    return value as NonNullable<T>
}


function getProtoype(val: unknown): string {
    return Object.prototype.toString.call(val)

}

export const round = (num: number, precision: number): number => {
    precision = Math.pow(10, precision)
    return Math.ceil(num * precision) / precision
}


export const sanitizeForFilename = (unsafe: string) => unsafe.replace(/[<>:"\/\\|?*]/g, ' ');


export const identity = <T>(x: T): T => x
export const isFalse = (x: boolean): boolean => !x


export const tab = (slowo: unknown, dlugosc: number): string => {
    const ciag = String(slowo)
    return (ciag + "                                   ").slice(0, dlugosc)
}


export const reversed = <T>(org: readonly T[]): T[] => {
    const reverse = [...org]
    reverse.reverse()
    return reverse
}

export const timeIt = (requestingMoment: number) => async (): Promise<number> => {
    return Date.now() - requestingMoment
}


/////////////////////////////////////////////////////////////////////////
//                   put all array functions beneath                   //
/////////////////////////////////////////////////////////////////////////


export const shuffleInPlace = <T>(array: T[], randomGenerator: () => number) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(randomGenerator() * (i + 1));
        [array[i], array[j]] = [array[j]!, array[i]!];
    }
    return array
}


export const functionsOnBackend = (ar: number[]) => {
    return sumArray(ar) / ar.length
}

export const sortedNumbers = (unsorted: number[]): number[] => {
    return [...unsorted].sort((a, b) => a - b)
}

export const sorted = <T>(unsorted: readonly T[], key: (el: T) => number): T[] => {
    return [...unsorted].sort((a, b) => key(a) - key(b))
}

export const reverseSorted = <T>(unsorted: readonly T[], key: (el: T) => number): T[] => {
    return [...unsorted].sort((a, b) => key(b) - key(a))
}


export const minBy = <T>(l: T[], func: (x: T) => number): T => extremum(l, func, (a, b) => a < b)

export const maxBy = <T>(l: T[], func: (x: T) => number): T => extremum(l, func, (a, b) => a > b)

const extremum = <T>(l: T[], f: (x: T) => number, compare: (a: number, b: number) => boolean): T =>
    l.reduce((a, b) => compare(f(a), f(b)) ? a : b)

export function sumArray(ar: number[]): number {
    return ar.reduce((a, b) => a + b, 0)
}

export const average = (numbers: number[]) => {
    if (numbers.length > 0) {
        return sumArray(numbers) / numbers.length
    } else {
        throw new Error("division by 0 in average function")
    }
}

export function elementAt<T>(al: ArrayLike<T>, i: number): T {
    const element = al[i < 0 ? al.length + i : i]
    if (element === undefined) {
        throw new Error(`Element at ${i} in "${al}" of type ${typeof al} is undefined`)
    }
    return element
}

// export function valueAt<T>(mp: Map<string,T>, key: string): T { // todo dodac inne typy klucza
//     const element = mp.get(key)
//     if (element === undefined) {
//         throw new Error(`Value at key ${key} in "${mp}" of type is undefined`)
//     }
//     return element
// }


export const cycleOver = <T>(collection: T[], index: number): T => {
    const element = collection[index % collection.length]
    if (element === undefined) {
        throw new Error("Wybrany Element to undefined")
    }
    return element
}

export const firstEl = <T>(ar: ArrayLike<T> | readonly T[]): T => {
    if (ar.length === 0) {
        throw Error(`List is empty`)
    } else {
        return ar[0]!
    }
}

export const lastEl = <T>(ar: ArrayLike<T> | readonly T[]): T => {
    if (ar.length === 0) {
        throw Error(`List is empty`)
    }
    return ar[ar.length - 1]!
}


export const movingAverage = (data: number[], period: number) => {
    const newList = []
    const movingAverage = []
    for (const n of data) {
        newList.push(n)
        const trimmed = newList.slice(-period,)
        movingAverage.push(trimmed.reduce((a, b) => a + b) / trimmed.length)
    }
    return movingAverage
}

export const groupWith = <T>(collection: T[], test: (el: T) => boolean): [T[], T[]] => {
    const passed = []
    const failed = []
    for (const el of collection) {
        if (test(el)) {
            passed.push(el)
        } else {
            failed.push(el)
        }
    }
    return [passed, failed]
}


export const arrayMean = (ar: number[]) => {
    return sumArray(ar) / ar.length
}


export const safeRatio = (ofWhat: number, toWhat: number) => {
    const ratio = ofWhat / toWhat
    if (isFinite(ratio) && !isNaN(ratio)) {
        return ratio
    } else {
        throw new Error(`dzielenie ${ofWhat} przez (${ofWhat}) dalo  bezsensowny wynik: ${ratio}`)
    }
}


export const insertWithoutRepetitions = <T>(poz: number, inserting: T, collection: T[]) => {
    if ([2, 1, 0].filter(num => collection[poz - num] === inserting).length === 0) {
        collection.splice(poz, 0, inserting)
    }
}


export const firstToEnd = <T>(list: T[]): T[] => {
    const firstElement = firstEl(list)
    const rest = list.slice(1,)
    rest.push(firstElement)
    return rest
}
export const firstElOnPlace = <T>(arr: T[], insertIndex: number): T[] => {
    beSure(insertIndex > 1, `cannot put element on position ${insertIndex}`)
    const firstElement = firstEl(arr)
    const firstChunk = arr.slice(1, insertIndex)
    const secondChunk = arr.slice(insertIndex,)
    return [...firstChunk, firstElement, ...secondChunk]
}


export const tail = <T>(arr: readonly T[]): T[] => {
    beSure(arr.length > 1, "collection too short")
    return arr.slice(1, arr.length)

}


export const removeByValue = <T>(arr: T[], what: T) => {
    let ax
    while ((ax = arr.indexOf(what)) !== -1) {
        arr.splice(ax, 1);
    }
    return arr;
}

export const ensureIsString = (supposedString: unknown): string => {
    if (typeof supposedString === "string") {
        return supposedString
    } else {
        throw new Error(`value ${supposedString} must be a string, but is ${typeof supposedString}`)
    }
}

export const ensureNotEmptyString = (supposedString: unknown): string => {
    if (typeof supposedString === "string") {
        if (supposedString.length === 0) {
            throw new Error(`.len of string === 0`)
        }
        return supposedString
    } else {
        throw new Error(`value ${supposedString} must be a string, but is ${typeof supposedString}`)
    }
}

export function safeParseToNumber(str: unknown) {
    const number = Number(str)
    beSure(!Number.isNaN(number), "Should be number but is NaN")
    return number
}

export function safeParseToBool(str: unknown): boolean {
    if (str === "true") {
        return true
    } else if (str === "false") {
        return false
    } else {
        throw ("the string is not 'false' nor 'true'")
    }
}

export function safeParseToInt(str: string | undefined, fallback: number): number {
    if (str === undefined) {
        return fallback
    }
    const parsed = parseInt(str)
    if (isNaN(parsed)) {
        throw new Error(`Can't parse string ${str} to number `)
    }
    return parsed
}


type Primitive = string | number | bigint | boolean | symbol

type Stringifiable =
    | string     // String
    | number     // Number
    | boolean    // Boolean
    | { [key: string]: Stringifiable }  // Object with stringifiable values
    | Stringifiable[]  // Array of stringifiable items
    | Date       // Date (automatically converts to ISO string)

export function beSureNoDoubles(list: Stringifiable[], errMsg: string): void {
    const jsonedList = list.map(x => JSON.stringify(x))
    const seen = new Set<string>();
    const duplicates = new Set<string>();

    for (const item of jsonedList) {
        if (seen.has(item)) {
            duplicates.add(item);
        } else {
            seen.add(item);
        }
    }

    if (duplicates.size > 0) {
        console.log("Duplicates found:", Array.from(duplicates));
        throw new Error(errMsg);
    }
}

type RecordKey = string | number | symbol
export const getOr = <K extends RecordKey, V, X>(object: Record<K, V>, key: K, fallbackVal: X): V | X => {
    const val = object[key]
    return val !== undefined ? val : fallbackVal
}

export const safeGet = <K, V>(map: Map<K, V>, k: K): V => {
    const v = map.get(k)
    if (v === undefined) {
        throw Error(`valus of ${k} is undefined in map ${Object.values(map)}`)
    } else {
        return v
    }
}


export type NoPromiseObject = Record<string, any> & { then?: never };

export const SAFESON = {
    parse: (jsonString: string): unknown => JSON.parse(jsonString),
    stringify: (
        data: NoPromiseObject | number,
        replacer?: ((this: any, key: string, value: any) => any) | undefined,
        space?: string | number | undefined
    ): string => JSON.stringify(data, replacer, space)
}


export function removeProperty<T extends Record<string, any>, K extends keyof T>(input: T, propertyToRemove: K): Omit<T, K> {
    const {[propertyToRemove]: _, ...result} = input;
    return result;
}


//////////////////////////////////   RANDOM //////////////////////////////////////////

export const actuallyGoodChoice = <T>(choices: T[], seed: number) => {
    const index = Math.floor(randNumGenMulberry32(seed)() * choices.length);
    return choices[index];
}

/**
 * @deprecated This fun is deprecated. Use random.something( instead
 */
export const randomInt = (min: number, max: number, randomGenerator: () => number) => {
    return Math.floor(randomGenerator() * (max - min + 1)) + min;
}

/**
 * @deprecated This fun is deprecated. Use random.something( instead
 */
export const randomChoice = <T>(collection: T[], randomGenerator: () => number): T => {
    const index = Math.floor(randomGenerator() * collection.length);
    return nullCheck(collection[index]);
}

/**
 * @deprecated This fun is deprecated. Use random.something( instead
 */
export function randomShuffled<T>(array: readonly T[], randomGenerator: () => number) {
    const arr = [...array]
    let i, t, j;
    for (i = arr.length - 1; i > 0; i -= 1) {
        t = arr[i];
        j = Math.floor(randomGenerator() * (i + 1));
        arr[i] = arr[j]!;
        arr[j] = t!;
    }
    return arr;
}


export const random = {

    bool: (probability: number, seed: number): boolean => {
        return randNumGenMulberry32(seed)() < probability
    },

    float: (seed: number): number => {
        return randNumGenMulberry32(seed)()
    },

    choice: <T>(choices: T[], seed: number): T => {
        const index = Math.floor(randNumGenMulberry32(seed)() * choices.length)
        return elementAt(choices, index)
    },

    int: (min: number, max: number, seed: number): number => {
        return Math.floor(randNumGenMulberry32(seed)() * (max - min + 1)) + min
    },

    shuffled: <T>(array: readonly T[], seed: number): T[] => {
        const arr = [...array]
        let i, t, j;
        for (i = arr.length - 1; i > 0; i -= 1) {
            t = arr[i];
            j = Math.floor(randNumGenMulberry32(seed)() * (i + 1));
            arr[i] = arr[j]!;
            arr[j] = t!;
        }
        return arr;
    }

}