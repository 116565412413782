import {request} from "./Requests";
import {printBold} from "./frontendSpecificFunctions";
import {APICommand, ResponseDTO} from "./shared-modules/commandApi";
import {TopicWithStats} from "./shared-modules/materialTypes";
import {makeSceneTopics} from "./makeSceneTopics";
import {makeMaterialDescription} from "./makeMaterialDescription";
import {SceneTopic} from "./SceneTopic";


export async function getMaterialAndDownloadMultimedia(): Promise<SceneTopic[]> {
    const start = Date.now()
    const serverRes = await request(APICommand.GetMaterial, null) as ResponseDTO<TopicWithStats[]>
    const topics: SceneTopic[] = await makeSceneTopics(serverRes.data)
    console.log(makeMaterialDescription(topics))

    printBold(`ALL PRELOADED in ${Date.now() - start}`)
    return topics
}






