import {maxBy} from "./shared-modules/funkcje";
import {LangName, PairOfRichWords, RichWord} from "./shared-modules/materialTypes";
import {pairsAreConflicted} from "./findConflict";

type Pattern = {
    readonly string: string,
    weight: number

}

export function makeQuestionsBySimilarity(incomplete: PairOfRichWords, candidates: PairOfRichWords[]): PairOfRichWords[] {
    const patterns = splitToWords(incomplete.shownWord.string).map(s => ({string: s, weight: 1}))
    const companions: PairOfRichWords[] = []
    while (companions.length < 2 && candidates.length > 0) {
        const best = maxBy(candidates, el => similarityScore(el.shownWord, patterns))
        if (!pairsAreConflicted(incomplete, best)) {
            companions.push(best)
            const wordsOfBest = splitToWords(best.shownWord.string)
            lowerWeights(best.shownWord, patterns)
        }
        candidates = candidates.filter(c => c !== best)
    }
    return companions
}

function lowerWeights(x: RichWord, patterns: Pattern[]): void {
    for (const word of splitToWords(x.string)) {
        for (const pat of patterns) {
            if (areSimilar(pat.string, word, x.langName)) {
                pat.weight = pat.weight / 10
            }
        }
    }
}

function similarityScore(tested: RichWord, patterns: Pattern[]): number {
    let score = 0
    for (const word of splitToWords(tested.string)) {
        for (const pat of patterns) {
            if (areSimilar(pat.string, word, tested.langName)) {
                score += pat.weight
            }
        }
    }
    return score
}


function areSimilar(a: string, b: string, langName: LangName): boolean {
    let similar = false
    switch (langName) {
        case "de":
            const range = Math.min(a.length, b.length) / 2
            similar = beginningWithoutUmlauts(a, range) === beginningWithoutUmlauts(b, range)
            break
        case "en":
            similar = a === b
            break
        default:
            throw Error(`no such lang ${langName}`)
    }
    return similar
}

function splitToWords(sentence: string): string[] {
    return sentence.toLowerCase()
        .replace(",", "")
        .replace(".", "")
        .replace("?", "")
        .split(' ')
}

function beginningWithoutUmlauts(str: string, range: number): string {
    return str.substring(0, range + 1)
        .replace('ä', 'a')
        .replace('ü', 'u')
        .replace('ö', 'o')
}


