import {TopicWithStats} from "./shared-modules/materialTypes";
import {
    beSureNoDoubles, firstEl, random, reverseSorted, sumArray,
} from "./shared-modules/funkcje";
import {makeShortListOfPairs, SceneTopic} from "./SceneTopic";
import {makeScene} from "./Scene";

export async function makeSceneTopics(topics: TopicWithStats[]): Promise<SceneTopic[]> {
    const sceneTopics: SceneTopic[] = []
    const requiredLessonLengthen = 70

    const seed = firstEl(topics).seed

    const shuffled = random.shuffled(topics, seed)
    const sortedAgain = reverseSorted(shuffled, weightTopic)

    for (const topic of sortedAgain) {
        const pairsSoFar = sceneTopics.flatMap(t => t.pairs).length
        if (pairsSoFar >= requiredLessonLengthen) {
            break
        }
        if (haveWeight(topic) || topic.title.includes("VIP") || random.bool(0.33, topic.seed)) {
            const sceneTopic = await makeSceneTopic(topic)
            sceneTopics.push(sceneTopic)
        }
    }
    return sceneTopics
}

async function makeSceneTopic(topic: TopicWithStats): Promise<SceneTopic> {
    const shuffled = random.shuffled(topic.pairs, topic.seed)
    const sortedAgain = reverseSorted(shuffled, p => p.weight)
    const shortList = makeShortListOfPairs(sortedAgain)
    beSureNoDoubles(shortList.map(p => p.id), `doubles in: ${topic.title}`)
    const scenes = await Promise.all(shortList.map(p => makeScene(p, topic)))
    return {...topic, pairs: scenes}
}

function weightTopic(t: TopicWithStats): number {
    return sumArray(t.pairs.map(q => q.weight)) / t.pairs.length
}


function haveWeight(t: TopicWithStats): boolean {
    return t.pairs.filter(q => q.weight > 0).length > 0
}






